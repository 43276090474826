import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import {
  Col,
  Row,
  Typography,
  Form,
  Input,
  Button,
  Layout,
  Upload,
  Select,
  Image,
  Modal,
  message,
  InputNumber,
} from "antd";
import dayjs from "dayjs";
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons";
import { FaCaretDown, FaFilter, FaArrowLeft } from "react-icons/fa";
import { Get } from "../../config/api/get";
import { QUESTION, CATEGORIES } from "../../config/constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";
import { TbCameraPlus } from "react-icons/tb";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ImageUrl } from "../../helpers/Functions";
const { Option } = Select;
const { Dragger } = Upload;

const correctOptions = new Array(4)
  .fill("Option ")
  .map((val, ind) => val + (ind + 1));
const iconFontSize = 20;

function QuestionAdd() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [imageNew, setImageNew] = useState();
  const [fileList, setFileList] = useState([]);
  // const [questionArray, setQuestionArray] = useState(["MCQ"]);
  const [questionType, setQuestionType] = useState("MCQ");

  const { id } = useParams();

  const [form] = Form.useForm();
  const handleTabChange = (index) => {
    const types = ["MCQ", "TRUE_FALSE", "FILL_IN_THE_BLANKS"];
    setQuestionType(types[index]);
  };
  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setLoading(true);
    const res = await Get(`${CATEGORIES.getAllcategories}`, token, {
      limit: "100",
    });

    setCategories(res.data.docs);
    setLoading(false);
  };

  const handleStatus = async () => {
    try {
      const response = await Get(
        QUESTION.toggleStatus + "/" + question._id,
        token,
        {}
      );
      const newUser = { ...question };

      newUser.isActive = !question.isActive;
      setModalOpen(false);
      setQuestion(newUser);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = (values) => {
    const {
      title,
      option1,
      option2,
      option3,
      option4,
      correctOption,
      score,
      correctAnswer,
    } = values;
    setLoading(true);

    let obj = {
      title,
      score,
      quiz: id,
      type: questionType,
    };

    switch (questionType) {
      case "MCQ":
        obj = {
          ...obj,
          options: [option1, option2, option3, option4],
          correctOption:
            correctOption === "option1"
              ? option1
              : correctOption === "option2"
              ? option2
              : correctOption === "option3"
              ? option3
              : option4,
        };
        break;
      case "TRUE_FALSE":
        obj = {
          ...obj,
          correctOption,
        };
        break;
      case "FILL_IN_THE_BLANKS":
        obj = {
          ...obj,
          correctAnswer,
        };
        break;
      default:
        break;
    }

    Post(QUESTION.addQuestion, obj, token, null)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          console.log(response?.data);

          swal("Success!", "Question Added Successfully", "success");
          navigate(-1);

          setEditMode(false);
          setImageNew();
        } else {
          setLoading(false);
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  const uploadOptions = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    maxCount: 10,
    multiple: true,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        setFileList(info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "text/plain",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedTypes.includes(file.type)) {
        message.error(`${file.name} is not a valid file type.`);
        return false;
      }
      return true;
    },
  };

  // const addQuestion = (e) => {
  //   try {
  //     let { innerText } = e.target;
  //     let [, text] = innerText?.split(" ");
  //     setQuestionArray([...questionArray, text]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const removeQuestion = (ind) => {
  //   try {
  //     let arr = [...questionArray];
  //     arr.splice(ind, 1);
  //     setQuestionArray(arr);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const [elRefs, setElRefs] = useState([])

  // setElRefs((val) =>
  // questionArray
  //     .map((_, i) => val[i] || createRef()),
  // );

  // console.log(elRefs[0]?.current)

  // const myRefs = useRef([]);

  // myRefs.current = questionArray.map((element, i) => myRefs.current[i] ?? createRef());

  // const myRefs = questionArray.map(() => useRef(null))

  // console.log("aaa", myRefs[0])

  // const btnRef = useRef(null)
  // console.log('a', btnRef)

  // const [enabledForm, setEnabledForm] = useState(questionArray?.length - 1)

  // const enabledForm = useMemo(() => {
  //   return questionArray?.length - 1;
  // }, [questionArray]);

  return (
    <Layout className="configuration">
      <div className="boxDetails2">
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaArrowLeft
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onClick={() => navigate(-1)}
            />
            &emsp;
            <h1 className="pageTitle" style={{ margin: 0 }}>
              Add Question
            </h1>
          </Col>
        </Row>
        <br />
        <div>
          <Tabs onSelect={handleTabChange}>
            <TabList>
              <Tab>MCQ</Tab>
              <Tab>True / False</Tab>
              <Tab>Fill In The Blanks</Tab>
            </TabList>

            <TabPanel>
              <Form
                layout="vertical"
                name="basic"
                className="contactForm"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row style={{ padding: "20px", backgroundColor: "" }}>
                  <Col xs={24} md={18}>
                    <Row gutter={20}>
                      <Col
                        xs={24}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Title :
                        </Typography.Title>

                        <Form.Item
                          name={`title`}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Question",
                            },
                          ]}
                        >
                          <Input
                            // disabled={disabled}
                            size="large"
                            placeholder="Enter Question"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={20}>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Option 1:
                        </Typography.Title>

                        <Form.Item
                          name="option1"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Option",
                            },
                          ]}
                        >
                          <Input
                            // disabled={disabled}
                            size="large"
                            placeholder="Enter Option"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Option 2:
                        </Typography.Title>

                        <Form.Item
                          name="option2"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Option",
                            },
                          ]}
                        >
                          <Input
                            // disabled={disabled}
                            size="large"
                            placeholder="Enter Option"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Option 3:
                        </Typography.Title>

                        <Form.Item
                          name="option3"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Option",
                            },
                          ]}
                        >
                          <Input
                            // disabled={disabled}
                            size="large"
                            placeholder="Enter Option"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Option 4:
                        </Typography.Title>

                        <Form.Item
                          name="option4"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Option",
                            },
                          ]}
                        >
                          <Input
                            // disabled={disabled}
                            size="large"
                            placeholder="Enter Option"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={20}>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Correct Option :
                        </Typography.Title>

                        <Form.Item
                          name="correctOption"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please select question correctOption",
                            },
                          ]}
                        >
                          <Select
                            // disabled={disabled}
                            placeholder="Select Option"
                            className="signupSelectBox"
                          >
                            {correctOptions.map((item, index) => {
                              return (
                                <Option
                                  value={item.replace(/\s/g, "").toLowerCase()}
                                >
                                  {item}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Score :
                        </Typography.Title>

                        <Form.Item
                          name="score"
                          // initialValue={course?.score}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Score",
                            },
                          ]}
                        >
                          <InputNumber
                            size="large"
                            placeholder="Enter Score"
                            className="signupFormInput"
                            type="number"
                            min={0}
                            max={100}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row style={{ marginTop: 30 }}>
                  <>
                    {" "}
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="loginButton"
                      disabled={loading}
                      // onClick={()=>{myRefs[0].current.click()}}
                    >
                      {loading ? "Loading..." : "Add Question"}
                    </Button>
                    &emsp;
                    <Button
                      className="fontFamily1"
                      style={{
                        marginTop: "0px",
                        padding: "10px 30px",
                        cursor: "pointer",
                        color: "black",
                        borderRadius: "5px",
                        height: "auto",
                        border: "1px solid #203657",
                        fontWeight: "bold",
                      }}
                      ghost
                      size="large"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditMode(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                </Row>
              </Form>
            </TabPanel>
            <TabPanel>
              <Form
                layout="vertical"
                name="basic"
                className="contactForm"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row style={{ padding: "20px", backgroundColor: "" }}>
                  <Col xs={24} md={18}>
                    <Row gutter={20}>
                      <Col
                        xs={24}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Title :
                        </Typography.Title>

                        <Form.Item
                          name={`title`}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Question",
                            },
                          ]}
                        >
                          <Input
                            // disabled={disabled}
                            size="large"
                            placeholder="Enter Question"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={20}>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Correct Option :
                        </Typography.Title>

                        <Form.Item
                          name="correctOption"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please select question correctOption",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Option"
                            className="signupSelectBox"
                          >
                            <Option value={"true"}>True</Option>
                            <Option value={"false"}>False</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Score :
                        </Typography.Title>

                        <Form.Item
                          name="score"
                          // initialValue={course?.score}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Score",
                            },
                          ]}
                        >
                          <InputNumber
                            size="large"
                            placeholder="Enter Score"
                            className="signupFormInput"
                            type="number"
                            min={0}
                            max={100}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row style={{ marginTop: 30 }}>
                  <>
                    {" "}
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="loginButton"
                      disabled={loading}
                      // onClick={()=>{myRefs[0].current.click()}}
                    >
                      {loading ? "Loading..." : "Add Question"}
                    </Button>
                    &emsp;
                    <Button
                      className="fontFamily1"
                      style={{
                        marginTop: "0px",
                        padding: "10px 30px",
                        cursor: "pointer",
                        color: "black",
                        borderRadius: "5px",
                        height: "auto",
                        border: "1px solid #203657",
                        fontWeight: "bold",
                      }}
                      ghost
                      size="large"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditMode(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                </Row>
              </Form>
            </TabPanel>
            <TabPanel>
              <Form
                layout="vertical"
                name="basic"
                className="contactForm"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row style={{ padding: "20px", backgroundColor: "" }}>
                  <Col xs={24} md={18}>
                    <Row gutter={20}>
                      <Col
                        xs={24}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Title :
                        </Typography.Title>

                        <Form.Item
                          name={`title`}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Question",
                            },
                          ]}
                        >
                          <Input
                            // disabled={disabled}
                            size="large"
                            placeholder="Enter Question"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={20}>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Correct Answer :
                        </Typography.Title>

                        <Form.Item
                          name="correctAnswer"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please type Correct Answer",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Corect Answer"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Score :
                        </Typography.Title>

                        <Form.Item
                          name="score"
                          // initialValue={course?.score}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Score",
                            },
                          ]}
                        >
                          <InputNumber
                            size="large"
                            placeholder="Enter Score"
                            className="signupFormInput"
                            type="number"
                            min={0}
                            max={100}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row style={{ marginTop: 30 }}>
                  <>
                    {" "}
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="loginButton"
                      disabled={loading}
                      // onClick={()=>{myRefs[0].current.click()}}
                    >
                      {loading ? "Loading..." : "Add Question"}
                    </Button>
                    &emsp;
                    <Button
                      className="fontFamily1"
                      style={{
                        marginTop: "0px",
                        padding: "10px 30px",
                        cursor: "pointer",
                        color: "black",
                        borderRadius: "5px",
                        height: "auto",
                        border: "1px solid #203657",
                        fontWeight: "bold",
                      }}
                      ghost
                      size="large"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditMode(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                </Row>
              </Form>
            </TabPanel>
          </Tabs>
        </div>

        <br />
        <br />
      </div>

      <Modal
        open={modalOpen}
        onOk={() => handleStatus()}
        onCancel={() => setModalOpen(false)}
        okText="Yes"
        className="StyledModal"
        style={{
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}
        cancelText="No"
        cancelButtonProps={{
          style: {
            border: "2px solid #2484c6",
            color: "#2484c6",
            height: "auto",
            padding: "6px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            marginTop: "15px",
          },
        }}
        okButtonProps={{
          style: {
            backgroundColor: "#2484c6",
            color: "white",
            marginTop: "15px",
            height: "auto",
            padding: "5px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            border: "2px solid #2484c6",
          },
        }}
      >
        <Image
          // src="../images/question.png"
          src={ImageUrl("question.png")}
          preview={false}
          width={100}
          height={120}
        />
        <Typography.Title level={4} style={{ fontSize: "25px" }}>
          {question?.isActive ? "Deactivate" : "Activate"}
        </Typography.Title>
        <Typography.Text style={{ fontSize: 16 }}>
          Do You Want To {question?.isActive ? "Deactivate" : "Activate"} This
          User?
        </Typography.Text>
      </Modal>

      <br />
      <br />
    </Layout>
  );
}
export default QuestionAdd;

const QuestionForm = (props) => {
  const disabled = props.enabledForm !== props.ind;

  const onFinish = (e) => {
    console.log(e);
  };
  return <></>;
};

const MCQForm = (props) => {
  return (
    <>
      <Row style={{ padding: "20px", backgroundColor: "" }}>
        <Col xs={24} md={18}>
          <Row justify={"end"}>
            <Col style={{ display: "flex", gap: 10 }}>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.removeQuestion(props.ind);
                }}
              />
              <CloseCircleOutlined
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.removeQuestion(props.ind);
                }}
              />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col
              xs={24}
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                Question :
              </Typography.Title>

              <Form.Item
                name="title"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input Question",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Question"
                  className="signupFormInput"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col
              xs={24}
              sm={12}
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                Option 1:
              </Typography.Title>

              <Form.Item
                name="option1"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input Option",
                  },
                ]}
                initialValue={"Yes"}
              >
                <Input
                  size="large"
                  placeholder="Enter Option"
                  // disabled
                  className="signupFormInput"
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={12}
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                Option 2:
              </Typography.Title>

              <Form.Item
                name="option2"
                initialValue={"No"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input Option",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Option"
                  className="signupFormInput"
                  // disabled
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col
              xs={24}
              sm={12}
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                Correct Option :
              </Typography.Title>

              <Form.Item
                name="category"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please select question category",
                  },
                ]}
              >
                <Select placeholder="Select Option" className="signupSelectBox">
                  {correctOptions.slice(0, 2).map((item, index) => {
                    return <Option value={item}>{item}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
