import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Input,
  Button,
  Popover,
  Layout,
  Avatar,
  Tabs,
  Table,
  Select,
  Image,
  Modal,
  Skeleton,
  Collapse,
} from "antd";
import dayjs from "dayjs";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FaCaretDown, FaFilter, FaArrowLeft } from "react-icons/fa";
import { Get } from "../../config/api/get";
import { BASE_URL, REPORT, UPLOAD_URL , USERS } from "../../config/constants";
import { useNavigate,useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from './reportDetails.module.css'
import axios from "axios";

const StudentCertificate = ({ studentData }) => {
  const token = useSelector((state) => state.user.userToken);
  
  const generateCertificate = async (download = true, view = true) => {
    try {
      const response = await axios.get(`${BASE_URL}/certificate/generate`, {
        params: { name: studentData?.firstName },
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/pdf',
        },
        responseType: 'blob', // Important for handling binary data like PDFs
      });

      // Create a blob from the PDF response
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      // Create a URL for the blob
      const pdfUrl = window.URL.createObjectURL(pdfBlob);

      // Open the PDF in a new tab
      if (view) {
        window.open(pdfUrl, '_blank');
      }

      if (download) {
        // Optional: Automatically trigger the download
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.setAttribute('download', `${studentData?.firstName}-certificate.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error generating certificate:', error);
      // Handle the error (e.g., show a message to the user)
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Student Certificate Eligibility</h2>
      
      <div className={styles.studentInfo}>
        <h3 className={styles.studentName}>{studentData?.firstName}</h3>
        <p className={styles.email}>Email: {studentData?.email}</p>
      </div>
      
      <div className={styles.coursesList}>
        <h4 className={styles.coursesHeading}>Courses</h4>
        {studentData?.courses.length > 0 ? (
          studentData?.courses.map((course) => (
            <div key={course._id} className={styles.courseItem}>
              <h5 className={styles.courseTitle}>{course.title}</h5>
              {course.quizes.length > 0 ? (
                course.quizes.map((quiz) => (
                  <div key={quiz._id} className={styles.quizItem}>
                    <p className={styles.quizTitle}>Quiz Title: {quiz.title}</p>
                    <p className={styles.quizDetail}>Passing Percentage: {quiz.passingPercentage}%</p>
                    <p className={styles.quizDetail}>Total Score: {quiz.totalScore}</p>
                    {quiz.attempts.map((attempt) => (
                      <div key={attempt._id} className={styles.attemptItem}>
                        <p className={styles.attemptDetail}>Score: {attempt.score}</p>
                        <p className={styles.attemptDetail}>Status: {attempt.status}</p>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <p className={styles.noQuiz}>No quizzes available for this course.</p>
              )}
              <p className={`${styles.eligibilityStatus} ${course.eligibleForCertificate ? styles.eligible : styles.notEligible}`}>
                {course.eligibleForCertificate ? 'Eligible for Certificate' : 'Not Eligible for Certificate'}
              </p>
              {course.eligibleForCertificate && (
                <div className={styles.buttonContainer}>
                  <button 
                    className={styles.certificateButton} 
                    onClick={generateCertificate}
                  >
                    Generate Certificate
                  </button>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className={styles.noCourses}>No courses enrolled.</p>
        )}
      </div>
    </div>
  );
};

function ReportDetails() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {id} = useParams()
  const [user,setUser]=useState(null);

  useEffect(() => {
    getUser();
  }, [])


  const getUser = async () => {
    setLoading(true);
    const user = await Get(`${REPORT.getStudentReport}`, token, {studentId: id});

    console.log("userss",user)
    debugger
    setUser(user);
    setLoading(false);
  };

  const handleStatus = async () => {
    try {
      const response = await Get(USERS.toggleStatus + user._id , token,{});
      const newUser = {...user};
      newUser.status = newUser.status =="ACTIVE" ? "INACTIVE" : "ACTIVE";
      
      // newUser.status =="ACTIVE" ? user.status =="INACTIVE" : user.status =="ACTIVE";
      setModalOpen(false);
      setUser(newUser);
    } catch (error) {
      console.log(error.message);
    }  
    
  };
  return (
    <StudentCertificate studentData={user?.length && user[0]} />
  )

  return (
    <Layout className="configuration">
      <div className="boxDetails2">
     
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaArrowLeft
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onClick={() => navigate(-1)}
            />
            &emsp;
            <h1 className="pageTitle" style={{ margin: 0 }}>
              Report
            </h1>
          </Col>
        </Row>
        <br />
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Skeleton active paragraph={{ rows: 10 }} />
          </div>
        )}

        {!loading && user && (
          <>
            <Row style={{ padding: "20px 30px" }}>
              <Col xs={12} sm={6}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  First Name
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    textAlign: "left",
                  }}
                >
                  {user?.firstName}
                </Typography.Text>
              </Col>

              <Col xs={12} sm={6}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  Last Name
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    textAlign: "left",
                  }}
                >
                  {user?.lastName}
                </Typography.Text>
              </Col>

              <Col xs={12} sm={6}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  Date of Birth
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    textAlign: "left",
                  }}
                >
                  {dayjs(user?.birthday).format("MM/DD/YYYY")}
                </Typography.Text>
              </Col>
            </Row>

            <Row style={{ padding: "20px 30px" }}>
              <Col xs={12} sm={12}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  Name of Parent/ Guardian
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    textAlign: "left",
                  }}
                >
                  {user?.parent}
                </Typography.Text>
              </Col>

              <Col xs={12} sm={6}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  Email Address
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    textAlign: "left",
                  }}
                >
                  {user?.email}
                </Typography.Text>
              </Col>
            </Row>

            <Row style={{ padding: "20px 30px" }}>
              <Col xs={12} sm={6}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  Phone Number
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    textAlign: "left",
                  }}
                >
                  {user?.phoneNumber}
                </Typography.Text>
              </Col>

              <Col xs={12} sm={6}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  Home Number
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    textAlign: "left",
                  }}
                >
                  {user?.homeNumber}
                </Typography.Text>
              </Col>

              <Col xs={12} sm={6}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  School Name
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    textAlign: "left",
                  }}
                >
                  {user?.school}
                </Typography.Text>
              </Col>
            </Row>

            <Row style={{ padding: "20px 30px" }}>
              <Col xs={12} sm={6}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  Grade Level
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    textAlign: "left",
                  }}
                >
                  {user?.gradeLevel}
                </Typography.Text>
              </Col>

              <Col xs={12} sm={6}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  City
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    textAlign: "left",
                  }}
                >
                  {user?.city}
                </Typography.Text>
              </Col>

              <Col xs={12} sm={6}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  State
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    textAlign: "left",
                  }}
                >
                  {user?.state}
                </Typography.Text>
              </Col>
            </Row>

            <Row style={{ padding: "20px 30px" }}>
              <Col xs={12} sm={6}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  Zip Codes
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    textAlign: "left",
                  }}
                >
                  {user?.zip}
                </Typography.Text>
              </Col>

              <Col xs={12} sm={12}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  Interested Subjects
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    textAlign: "left",
                  }}
                >
                  {user?.subjects.join(" , ")}
                </Typography.Text>
              </Col>
            </Row>

            <Row style={{ padding: "20px" }}>
              {user.status == "ACTIVE" ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="loginButton2"
                  onClick={() => setModalOpen(true)}
                  style={{ fontWeight: "bold" }}
                >
                  Inactive
                </Button>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="loginButton"
                  onClick={() => setModalOpen(true)}
                  style={{ fontWeight: "bold" }}
                >
                  Acitve
                </Button>
              )}
              &emsp;
              <Button
                className="fontFamily1"
                style={{
                  marginTop: "0px",
                  padding: "10px 30px",
                  cursor: "pointer",
                  color: "black",
                  borderRadius: "5px",
                  height: "auto",
                  border: "1px solid #203657",
                  fontWeight: "bold",
                }}
                ghost
                size="large"
              >
                Message
              </Button>
            </Row>
          </>
        )}
      </div>

    </Layout>
  );
}
export default ReportDetails;
