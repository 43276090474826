import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Input,
  Button,
  Popover,
  Layout,
  Avatar,
  Upload,
  Table,
  Select,
  Image,
  Modal,
  DatePicker,
  Skeleton,
  message,
  TimePicker,
  InputNumber,
} from "antd";
import dayjs from "dayjs";
import {
  UserOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  PlusCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { FaCaretDown, FaFilter, FaArrowLeft } from "react-icons/fa";
import { Get } from "../../config/api/get";
import {
  UPLOAD_URL,
  QUESTION,
  ADMIN,
  CONTENT_TYPE,
  CATEGORIES,
  COURSE,
  QUIZ,
} from "../../config/constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";
import { TbCameraPlus } from "react-icons/tb";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { ImageUrl } from "../../helpers/Functions";
import moment from "moment";
const { Option } = Select;
const { Dragger } = Upload;

const correctOptions = new Array(4)
  .fill("Option ")
  .map((val, ind) => val + (ind + 1));
const iconFontSize = 20;

function QuizAdd() {
  const [form] = Form.useForm(); // Create form instance

  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState(null);
  const [course, setCourse] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [imageNew, setImageNew] = useState();
  const [fileList, setFileList] = useState([]);
  const [questionArray, setQuestionArray] = useState(["MCQ"]);
  const [students, setStudents] = useState([]);


  useEffect(() => {
    getCourses();
  }, []);

  const getCourses = async () => {
    setLoading(true);
    const res = await Get(`${COURSE.getAllCourses}`, token, {
      limit: "100",
    });

    setCourse(res.data.docs);
    setLoading(false);
  };

  const handleStatus = async () => {
    try {
      const response = await Get(
        QUESTION.toggleStatus + "/" + question._id,
        token,
        {}
      );
      const newUser = { ...question };

      newUser.isActive = !question.isActive;
      setModalOpen(false);
      setQuestion(newUser);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    let obj = { ...values,  questions: [] };
    Post(QUIZ.addQuiz, obj, token, null)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          console.log(response?.data);

          swal("Success!", "Quiz Added Successfully", "success");
          navigate(-1);

          setEditMode(false);
          setImageNew();
        } else {
          setLoading(false);
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };
  const handleCourseChange = async (courseId) => {
    const res = await Get(`${COURSE.getEnrolledStudens}${courseId}`, token);
    if (res.status) {
      setStudents(res?.data?.students);
    }
    form.setFieldsValue({ students: [] }); 
  };
  return (
    <Layout className="configuration">
      <div className="boxDetails2">
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaArrowLeft
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onClick={() => navigate(-1)}
            />
            &emsp;
            <h1 className="pageTitle" style={{ margin: 0 }}>
              Add Quiz
            </h1>
          </Col>
        </Row>
        <br />

        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete={false}
          form={form}
        >
          <Row style={{ padding: "20px", backgroundColor: "" }}>
            <Col xs={24} md={18}>
              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Name :
                  </Typography.Title>

                  <Form.Item
                    name={`title`}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please insert Name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Name"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Course :
                  </Typography.Title>

                  <Form.Item
                    name="course"
                    initialValue={course?.course?._id}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select course course",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Course"
                      className="signupSelectBox"
                      onChange={handleCourseChange}
                    >
                      {course.map((item, index) => {
                        return (
                          <Option
                            disabled={item.status !== "ACTIVE"}
                            value={item._id}
                          >
                            {item.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                {/* <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Due Date :
                  </Typography.Title>

                  <Form.Item
                    name={`quizDate`}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please insert Due Date",
                      },
                    ]}
                  >
                    <DatePicker size="large"
                      placeholder="Enter Date"
                      className="signupFormInput"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col> */}

                <Col
                  xs={24}
                  sm={12}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Select Students :
                  </Typography.Title>
                  <Form.Item name="students" style={{ width: "100%" }}>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Please select"
                      options={students.map((student) => ({
                        label: `${student.firstName} ${student.lastName}`, // Display Name
                        value: student._id, // Store _id on selection
                      }))}
                      className="custum-multiselect"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Passing Percentage :
                  </Typography.Title>

                  <Form.Item
                    name="passingPercentage"
                    initialValue={course?.passingPercentage}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Passing Percentage",
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      placeholder="Enter Passing Percentage"
                      className="signupFormInput"
                      min={0}
                      max={100}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Description :
                  </Typography.Title>

                  <Form.Item
                    name="description"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Description",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Description"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={18}>
              <Row gutter={20}>
                <Col
                  xs={24}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    paddingTop: 10,
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Add Quiz"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        <br />
        <br />
      </div>

      <Modal
        open={modalOpen}
        onOk={() => handleStatus()}
        onCancel={() => setModalOpen(false)}
        okText="Yes"
        className="StyledModal"
        style={{
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}
        cancelText="No"
        cancelButtonProps={{
          style: {
            border: "2px solid #2484c6",
            color: "#2484c6",
            height: "auto",
            padding: "6px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            marginTop: "15px",
          },
        }}
        okButtonProps={{
          style: {
            backgroundColor: "#2484c6",
            color: "white",
            marginTop: "15px",
            height: "auto",
            padding: "5px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            border: "2px solid #2484c6",
          },
        }}
      >
        <Image
          // src="../images/question.png"
          src={ImageUrl("question.png")}
          preview={false}
          width={100}
          height={120}
        />
        <Typography.Title level={4} style={{ fontSize: "25px" }}>
          {question?.isActive ? "Deactivate" : "Activate"}
        </Typography.Title>
        <Typography.Text style={{ fontSize: 16 }}>
          Do You Want To {question?.isActive ? "Deactivate" : "Activate"} This
          User?
        </Typography.Text>
      </Modal>

      <br />
      <br />
    </Layout>
  );
}
export default QuizAdd;
