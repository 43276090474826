import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  message,
  Form,
  Input,
  Button,
  Popover,
  Layout,
  Avatar,
  Upload,
  Table,
  Select,
  Image,
  Modal,
  DatePicker,
  Skeleton,
} from "antd";
import dayjs from "dayjs";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { FaCaretDown, FaFilter, FaArrowLeft } from "react-icons/fa";
import { Get } from "../../config/api/get";
import {
  UPLOAD_URL,
  LECTURES,
  COURSE,
  CONTENT_TYPE,
  CATEG,
  LECTURESORIES,
} from "../../config/constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";
import { TbCameraPlus } from "react-icons/tb";
import VideoPlayer from "../../components/VideoPlayer2";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
const { Option } = Select;

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function LectureAdd() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lectureloading, setLectureLoading] = useState(false);
  const { id } = useParams();
  const [lecture, setLecture] = useState(null);
  const [courses, setCourses] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    getCourses();
  }, []);

  const getCourses = async () => {
    setLoading(true);
    try {
      const response = await Get(COURSE.getAllCourses, token, {
        page: "1",
        limit: "100",
      });
      setLoading(false);
      console.log("response", response);
      if (response?.data?.docs) {
        setCourses(response?.data?.docs);
      } else {
        message.error("Something went wrong!");
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const onFinish = (values) => {
    setLectureLoading(true);
    const formObject = new FormData();

    if (values.video) {
      formObject.append("video", values.video.fileList[0].originFileObj);
    }
    if (imageList.length > 0) {
      imageList.forEach((file) => {
        formObject.append("images", file.originFileObj);
      });
    }
    for (const key in values) {
      if (key !== "video") {
        const item = values[key];
        formObject.append(key, item);
      }
    }
    fileList.forEach((file) => {
      formObject.append("files", file.originFileObj);
    });
    Post(LECTURES.addLecture, formObject, token, null, CONTENT_TYPE.FORM_DATA)
      .then((response) => {
        if (response?.data?.status) {
          console.log(response?.data);

          swal("Success!", "Lecture Added Successfully", "success");
          navigate(-1);

          form.resetFields();
          setLectureLoading(false);
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        setLectureLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const uploadOptions = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    maxCount: 10,
    multiple: true,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        setFileList(info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "text/plain",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedTypes.includes(file.type)) {
        message.error(`${file.name} is not a valid file type.`);
        return Upload.LIST_IGNORE;
        // return false;
      }
      return true;
    },
  };
  const imageUploadOptions = {
    name: "images",
    multiple: true,
    listType: "picture",
    beforeUpload(file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        message.error(`${file.name} is not a valid image type.`);
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        setImageList(info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} uploaded successfully`);
      }
    },
  };

  return (
    <Layout className="configuration">
      <div className="boxDetails2">
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaArrowLeft
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onClick={() => navigate(-1)}
            />
            &emsp;
            <h1 className="pageTitle" style={{ margin: 0 }}>
              Add Lecture
            </h1>
          </Col>
        </Row>
        <br />
        <Form
          layout="vertical"
          name="basic"
          form={form}
          className="contactForm"
          labelCol={{
            span: 0,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row style={{ padding: "20px" }}>
            <Col xs={24} md={18}>
              <Row gutter={30} style={{ padding: "0 20px" }}>
                <Form.Item
                  name="video"
                  rules={[
                    {
                      required: true,
                      message: "Please Add Video",
                    },
                  ]}
                >
                  <Upload
                    name="video"
                    style={{ position: "relative" }}
                    beforeUpload={(file) => {
                      return false;
                    }}
                  >
                    <div
                      style={{
                        height: 300,
                        width: 500,
                        padding: 20,
                        display: "flex",
                        textAlign: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fafafa",
                        border: "2px dashed #dadada",
                        borderRadius: "10px",
                      }}
                    >
                      {" "}
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined style={{ fontSize: "30px" }} />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibited
                        from uploading company data or other banned files.
                      </p>
                    </div>
                  </Upload>
                </Form.Item>
              </Row>
              <Row gutter={20}>
                <Col xs={12} sm={12}>
                  <Upload {...uploadOptions}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Files
                    </Button>
                  </Upload>
                </Col>
              </Row>
                      <br />
              <Row gutter={20}>
                <Col xs={12} sm={12}>
                  <Upload {...imageUploadOptions}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Images
                    </Button>
                  </Upload>
                </Col>
              </Row>

              <br />

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Lecture No :
                  </Typography.Title>

                  <Form.Item
                    name="lectureNo"
                    initialValue={lecture?.lectureNo}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input your Lecture Number",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter lectureNo"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Title :
                  </Typography.Title>

                  <Form.Item
                    name="title"
                    initialValue={lecture?.title}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input lecture title",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Title"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Description:
                  </Typography.Title>

                  <Form.Item
                    name="description"
                    initialValue={lecture?.description}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Lecture description",
                      },
                    ]}
                  >
                    {/* <Input
                          size="large"
                          placeholder="Enter Description"
                          className="signupFormInput"
                        /> */}
                    <TextArea
                      rows={4}
                      placeholder="Description"
                      className="ContactFormInput2"
                      required={true}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Course :
                  </Typography.Title>

                  <Form.Item
                    name="course"
                    initialValue={lecture?.course._id}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input lecture course",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Course"
                      className="signupSelectBox"
                    >
                      {courses.map((item) => {
                        return <Option value={item._id}>{item?.title}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="loginButton"
                >
                  {lectureloading ? "Loading..." : "Add Lecture"}
                </Button>
                &emsp;
                <Button
                  className="fontFamily1"
                  style={{
                    marginTop: "0px",
                    padding: "10px 30px",
                    cursor: "pointer",
                    color: "black",
                    borderRadius: "5px",
                    height: "auto",
                    border: "1px solid #203657",
                    fontWeight: "bold",
                  }}
                  ghost
                  size="large"
                  onClick={(e) => {
                    e.preventDefault();
                    form.resetFields();
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>

        <br />
        <br />
      </div>

      <br />
      <br />
    </Layout>
  );
}
export default LectureAdd;
