import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Input,
  Rate,
  Spin,
  Image,
  Card,
  Typography,
} from "antd";
import { Get } from "../../config/api/get";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";

import { COMMENTS, UPLOAD_URL } from "../../config/constants";
import { ImageUrl } from "../../helpers/Functions";

function Reviews(props) {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleReviews, setVisibleReviews] = useState(10);
  const [message, setMessage] = useState("");
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDcs: 0,
    totalPages: 0,
  });
  const [hasMore, setHasMore] = useState(true);
  let courseId = props?.courseId;
  const token = useSelector((state) => state.user.userToken);
  const getCourseReviews = async (page = 1) => {
    setLoading(true);
    Get(`${COMMENTS.getComments}${courseId}`, token, {
      page,
      limit: paginationConfig.limit,
    })
      .then((response) => {
        if (response?.status) {
          setComments((prevComments) => [
            ...prevComments,
            ...response?.data?.docs,
          ]);
          setPaginationConfig({
            pageNumber: response?.data?.page,
            limit: response?.data?.limit,
            totalDocs: response?.data?.totalDocs,
            totalPages: response?.data?.totalPages,
          });
          setHasMore(response?.data?.page < response?.data?.totalPages);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching event comments ", err);
        setLoading(false);
      });
  };
  const handleLoadMore = () => {
    // setVisibleReviews((prev) => prev + 10);
    if (hasMore) {
      getCourseReviews(paginationConfig.pageNumber + 1);
    }
  };
  useEffect(() => {
    getCourseReviews();
  }, []);
  const handleCommentSubmit = async () => {
    if (message === "") {
      swal("Error", "Message is Required", "error");
      return;
    }
    const data = {
      text: message,
      courseId,
    };
    Post(COMMENTS.addComment, data, token)
      .then((response) => {
        if (response?.data?.status) {
          swal("Success!", response?.data?.message, "success");
          setMessage("");
          getCourseReviews();
        } else {
          if (response?.data?.message)
            swal("Oops!", response?.data?.message, "error");
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message || err?.message;
        console.log("add comment error", err);
        if (message) swal("Oops!", message, "error");
      });
  };
  return (
    <>
      {comments?.length ? (
        <Card className="contactCard2 mt-2">
          <Typography.Title
            className="fontFamily1"
            style={{
              fontSize: "28px",
              fontWeight: 800,
              color: "black",
              textAlign: "left",
              marginTop: 30,
            }}
          >
            Comments
          </Typography.Title>

          <div className="">
            {!loading ? (
              <Row justify="center">
                <Col lg={24} xs={23}>
                  {Array.isArray(comments) &&
                    comments.map((comment, index) => (
                      <div className="details-card">
                        <div key={index} className="review-box">
                          <div className="review-sugg">
                            <div
                              className="for-media-object"
                              style={{ display: "flex",alignItems:'center', marginBottom: "20px" }}
                            >
                              <div className="flex-shrink-0">
                                <Image
                                  src={
                                    comment?.user?.image
                                      ? UPLOAD_URL + comment?.user?.image
                                      : ImageUrl("img-avatar.png")
                                  }
                                  alt=""
                                  className="img-fluid"
                                  preview={false}
                                  style={{ width: "40px", height: "40px" }}
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h2 style={{fontWeight:'bold'}}>{comment?.user?.firstName}</h2>
                                <div className="star-18">
                                  <p style={{ wordBreak: "break-all" }}>
                                    {comment?.text}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <p>{review?.message}</p> */}
                        </div>
                      </div>
                    ))}
                  {hasMore && (
                    <button
                      className="f-18-blue"
                      onClick={handleLoadMore}
                      style={{
                        background: "none",
                        border: "none",
                        textDecoration: "underline",
                        textTransform: "capitalize",
                        cursor: "pointer",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      Load More Reviews
                    </button>
                  )}
                  {!hasMore && (
                    <p
                      className="f-18-blue"
                      style={{
                        textTransform: "capitalize",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      No More Reviews
                    </p>
                  )}
                  {/* {visibleReviews >= reviews.length && (
              <p
                className="f-18-blue"
                style={{
                  textTransform: "capitalize",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                No More Reviews
              </p>
            )} */}
                  <div
                    className="mb-3"
                    style={{
                      marginTop: "50px",
                      marginBottom: "50px",
                      background: "white",
                      padding: "20px",
                      borderRadius: "6px",
                    }}
                  ></div>
                </Col>
              </Row>
            ) : (
              <div>
                <Spin />
              </div>
            )}
          </div>
        </Card>
      ) : (
        <> </>
      )}
    </>
  );
}

export default Reviews;
